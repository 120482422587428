@import-normalize;

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500;600;700;800;900&family=Noto+Serif:wght@400;700&display=swap");

html {
  background: #ffffff;
  background-attachment: fixed;
  background-color: #ffffff;
}

body {
  font-family: "Noto Sans", sans-serif;
  letter-spacing: -0.03em;
  font-weight: 600;
  line-height: 1.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

input,
select,
textarea,
button {
  font-family: inherit;
  letter-spacing: inherit;
  word-spacing: inherit;
}

* {
  box-sizing: border-box;
}
